<template>
    <CModal title="Download Report" :show.sync="showModalDownloadFilter_" color="info" size="md" @close="() => { showModalDownloadFilter_ = false }">
        <form @submit.prevent="approve">
			<div class="pl-3 pr-3 pb-3">
                <h5>Choose a filter to download a report</h5><br>
				<CRow class="mb-2">
                    <CCol lg="12">
                        <label class="mb-1">Asset No.</label>
                        <CInput  v-model="filter.asset_no" placeholder="Asset No."/>
                        </CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Depot</label>
                        <v-select label="setting_name" :options="depot_list.data" :reduce="item => item.id" v-model="filter.depot_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Origin</label>
						<v-select label="customer_name" :options="origin_list.data" :reduce="item => item.id" v-model="filter.origin_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12" class="mb-3">
						<label class="mb-1">Current Location</label>
						<v-select label="customer_name" :options="current_list.data" :reduce="item => item.id" v-model="filter.current_location_id" placeholder="-Select-"></v-select>
					</CCol>
                    <CCol lg="12">
						<label class="mb-1">Description</label>
						<CInput v-model="filter.description"/>
					</CCol>
                    <CCol lg="12">
						<label class="mb-1">Type</label>
						<CInput v-model="filter.type"/>
					</CCol>
                    <CCol lg="12">
						<label class="mb-1">Model</label>
						<CInput v-model="filter.model"/>
					</CCol>
                    <CCol lg="12" class="mb-3">
                        <label class="mb-1">Status</label>
                        <v-select label="label" :options="status" :reduce="item => item.value" v-model="filter.status" placeholder="-Select-"></v-select>
                    </CCol>
                    <CCol lg="12" class="mb-3">
                        <h6 class="text-danger" v-if="no_filter">Please select a filter before downloading the report.</h6>
                    </CCol>
				</CRow>
            </div>
        </form>
        <div slot="footer" class="w-100">
            <!-- <CButton class="ml-1 mr-1 float-right" size="sm" color="info" @click="download"><i class="fa fa-file"></i> Generate Report</CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear"><i class="fa fa-close"></i> Clear</CButton> -->
            
            <CButton class="float-right" size="sm" color="success" @click="download" v-if="!processing"><i class="fa fa-file"></i> Generate Report</CButton>
            <a :href="link_download" v-if="ready_download"><i class="fa fa-file"></i> Download Report</a>
            <CButton color="primary" disabled v-if="processing && !ready_download">
                <CSpinner component="span" size="sm" variant="grow" aria-hidden="true"/>
                Generating Report. Please wait ...
            </CButton>
            <CButton class="ml-1 mr-1 float-right" size="sm" color="light" @click="clear" v-if="!processing"><i class="fa fa-close"></i> Clear</CButton>
        </div>
    </CModal>
</template>

<script>
import config from '../config.js';
import axios from '../axios';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
    components: { vSelect },
    mounted() {
        
    },
    props: {
        showModalDownloadFilter: {required: true},
        filters: {}
    },
    data() {
        return {
            showModalDownloadFilter_:false,
            filter: {
				origin_id: '',
				current_location_id:'',
				description:'',
                type:'',
                model:'',
				depot_id:'',
				status:'all',
                asset_no: ''
			},
            status:[
                {
                    label: 'All',
                    value: 'all'
                },
                {
                    label: 'Operational',
                    value: 'Operational'
                },

                // {
                //     label: 'Breakdown',
                //     value: 'breakdown'
                // },
                {
                    label: 'For Repair',
                    value: 'For Repair'
                },

                {
                    label: 'For Scrap',
                    value: 'for scrap'
                }
                // {
                //     label: 'Standby',
                //     value: 'standby'

                // },
                // {
                //     label: 'For Erection',
                //     value: 'for erection'
                // },
                // {
                //     label: 'For Inspection',
                //     value: 'for inspection'
                // },
                // {
                //     label: 'Reserve',
                //     value: 'reserve'
                // },
                // {
                //     label: 'Dismantle',
                //     value: 'dismantle'
                // },
                // {
                //     label: 'For Rehab',
                //     value: 'for rehab'
                // },
                // {
                //     label: 'Purchase',
                //     value: 'purchase'
                // }
            ],
            current_list:[],
            origin_list:[],
            depot_list:[],
            no_filter:false,
            currentPage:1,
            processing:false,
            ready_download:false,
            link_download:'',
            report_downloaded: false
        }
    },
    methods: {
        async download() {
            this.report_downloaded = false;
            if(
                true ||
				this.filter.origin_id ||
				this.filter.current_location_id ||
				this.filter.description ||
                this.filter.type ||
                this.filter.model ||
				this.filter.depot_id ||
				this.filter.status
            ){
                this.ready_download = false;
                this.processing = true;
                this.no_filter = false;
                var current_location_id = this.filter.current_location_id;
                if(current_location_id == "all" || current_location_id == null){
                    current_location_id = ""
                }
                var origin_id = this.filter.origin_id;
                if(origin_id == "All" || origin_id == null){
                    origin_id = ""
                } 
                var status = this.filter.status;
                if(status == "all" || status == null){
                    status = ""
                } 
             
                // await axios.get(config.api_path+'/reports/furnishing?'+
                // 'description='+this.filter.description+
                // '&current_location_id='+current_location_id+
                // '&origin_id='+origin_id+
                // '&status='+this.filter.status+
                // '&depot_id='+this.filter.depot_id
                // )
                await axios.post(`${config.api_path}/reports/furnishing`, {
                    description:this.filter.description,
                    current_location_id:current_location_id,
                    origin_id:origin_id,
                    status:this.filter.status,
                    type:this.filter.type,
                    model:this.filter.model,
                    depot_id:this.filter.depot_id,
                    asset_no: this.filter.asset_no
                    
                }).then(response=>{
                    this.link_download = config.main_path.replace('/index.php', '')+'/'+response.data.file
                    this.watchDownload(response.data.file);
                    // setTimeout(() => {
                    //     this.$showLoading(false)
                    //     window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file)
                      
                    // }, 3000);
                    // setTimeout(() => {
                    //     axios.get(`${config.api_path}/remove-file`,{
                    //         params : {
                    //             filename : response.data.file
                    //         }
                    //     })	
                    // }, 5000);
                    this.no_filter = false;
                })
                .catch(err => {
                    // this.$showLoading(false)
                    this.no_filter = true;
                    this.ready_download = false;
                    this.processing = false;
                });
            }
            else{
                this.no_filter = true;
                this.ready_download = false;
                this.processing = false;
            }
        },
        getCurrentLocation() {
			axios.get(config.api_path + '/reference/customer-list', {
				params: {show_all: true}
			}).then(response => {
                this.current_list = response.data;
            });
		},
        getOrigin() {
            axios.get(config.api_path + '/reference/customer-list', {
                params: {show_all: true}
            }).then(response => {
                this.origin_list = response.data;
            });
        },
        getDepot(){
            axios.get(config.api_path+'/reference/settings-list', {
                params:{ setting_type:'depot'}
             }) .then(response => {
                this.depot_list = response.data;
            });
        },
        clear(){
            this.filter.origin_id = '';
            this.filter.current_location_id = '';
            this.filter.description = '';
            this.filter.type = '';
            this.filter.model = '';
            this.filter.depot_id = '';
            this.filter.status = 'all';
            this.filter.asset_no = ''
        },
        watchDownload(file){
            const self = this;
            self.interval_import_program = setInterval(function () {
                axios.get(`${config.api_path}/watch-download`,{
                    params : {
                        filename : file
                    }
                }).then(response => {
                    if(response.data.status){
                        self.ready_download = true;
                        self.processing = false;
                        clearInterval(self.interval_import_program);
                        if(!self.report_downloaded) {
                            self.report_downloaded = true;
                            window.open(config.main_path.replace('/index.php', '')+'/'+file)
                        }
                    }
                });
            }, 1000);
        }
    },
    watch: {
        showModalDownloadFilter_: function(val){
            this.$emit('closeModalDownloadFilter', val)
        },
        showModalDownloadFilter: function(){
            this.showModalDownloadFilter_ = this.showModalDownloadFilter;

            if(this.processing || this.ready_download)
                return;

            if(!this.current_list?.data?.length)
                this.getCurrentLocation();
            if(!this.origin_list?.data?.length)
                this.getOrigin();
            if(!this.depot_list?.data?.length)
                this.getDepot();

            this.download()
        },
        filters: {
            deep: true,
            handler() {
                this.filter = {...this.filters}
                this.ready_download = false;
            }
        }
    },
}
</script>

<style>
.modal-header {
    background-color: white !important;
    
}
.modal-title {
    color: black;
}
</style>
